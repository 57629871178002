body {
  margin: 0px;
}

.title-non-margin {
  margin: 0;
}

.color-primary {
  background-color: #DC3238 !important;
  color: white;
}

.color-primary:hover {
  background-color: #B72934;
  border-color: #B72934 !important;
  color: white !important;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.text-white {
  color: white;
}

.mt-20 {
  margin-top: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 15px;
}

.header {
  height: auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  background-color: white;
  color: black;
}

.header-name {
  display: flex;
  align-items: center;
}

.content {
  min-height: 90vh;
  padding: 20px;
  background-color: white;
  color: black;
}

.footer {
  background-color: #DC3238;
  color: white;
}

.form-login-block {
  border: 2px solid #DC3238;
  padding: 15px;
  max-width: 600px;
  margin: auto;
  margin-top: 50px;
}

.login {
  /* margin-top: 25%; */
  background-color: white;
  text-align: center;
}

.table-risk {
  width: 100%;
  border-spacing: 0;
}

.table-scroll {
  overflow: scroll;
  /* text-align: center; */
  /* max-width: 1280px; */
  margin-top: 20px;
  width: auto;
}

/* 
table,
th,
td {
  border: 1px solid;
}

table {
  width: 100%;
  max-width: 400px;
  height: 240px;
  margin: 0 auto;
  display: block;
  overflow-x: auto;
  border-spacing: 0;
}

tbody {
  white-space: nowrap;
}

th,
td {
  padding: 5px 10px;
  border-top-width: 0;
  border-left-width: 0;
}

th {
  position: sticky;
  top: 0;
  background: #fff;
  vertical-align: bottom;
}

th:last-child,
td:last-child {
  border-right-width: 0;
}

tr:last-child td {
  border-bottom-width: 0;
} */

.upload-image-card {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.box-result {
  width: 300px;
  height: 150px;
  padding: 15px;
  font-size: large;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ql-editor {
  overflow-y: scroll;
  min-height: 250px;
}

/* Table */
.ant-table-thead>tr>th,
.ant-table-thead>tr>td {
  color: white !important;
}

.ant-table-tbody>tr>td {
  word-wrap: break-word;
  word-break: break-all;
}

.ant-table-thead>tr:not(:last-child)>th,
.ant-table-thead>tr:not(:last-child)>td {
  background-color: #DC3238 !important;
  border-top: 1px solid #3d3935 !important;
}

.ant-table-thead>tr:last-child>th,
.ant-table-thead>tr:last-child>td {
  background-color: #DC3238 !important;
}

.ant-table-thead>tr:not(:last-child)>th {
  background-color: #DC3238 !important;
  border-bottom: 1px solid #3d3935 !important;
}

.ant-table-thead>tr>th,
.ant-table-thead>tr>td,
.ant-table-tbody>tr>th,
.ant-table-tbody>tr>td,
.ant-table-tfoot>tr>th,
.ant-table-tfoot>tr>td,
.ant-table-thead>tr:not(:last-child)>th {
  border-bottom: 1px solid #3d3935 !important;
  border-inline-end: 1px solid #3d3935 !important;
}

.ant-table-thead>tr:first-child>th:first-child,
.ant-table-thead>tr:first-child>td:first-child,
.ant-table-tbody>tr>th:first-child,
.ant-table-tbody>tr>td:first-child,
.ant-table-tfoot>tr:first-child>th:first-child,
.ant-table-tfoot>tr:first-child>td:first-child,
.ant-table-tfoot>tr:last-child>th:first-child,
.ant-table-tfoot>tr:last-child>td:first-child {
  border-inline-start: 1px solid #3d3935 !important;
}

.multi-thead .ant-table-thead>tr:not(:last-child)>th {
  border-inline-start: 1px solid #3d3935 !important;
}

/* desktops/laptops */
@media screen and (min-width: 960px) {
  .nomobile {
    display: block;
  }

  .showmobile {
    display: none;
  }
}

/* mobile devices */
@media screen and (max-width: 960px) {
  .nomobile {
    display: none;
  }

  .showmobile {
    display: block;
  }
}

.ck-editor__editable {
  min-height: 250px;
}