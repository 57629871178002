body {
  margin: 0px;
}

.title-non-margin {
  margin: 0;
}

.color-primary {
  background-color: #DC3238 !important;
  color: white;
}

.color-primary:hover {
  background-color: #B72934;
  border-color: #B72934 !important;
  color: white !important;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.text-white {
  color: white;
}

.mt-20 {
  margin-top: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 15px;
}

.header {
  height: auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  background-color: white;
  color: black;
}

.header-name {
  display: flex;
  align-items: center;
}

.content {
  min-height: 90vh;
  padding: 20px;
  background-color: white;
  color: black;
}

.footer {
  background-color: #DC3238;
  color: white;
}

.form-login-block {
  border: 2px solid #DC3238;
  padding: 15px;
  max-width: 600px;
  margin: auto;
  margin-top: 50px;
}

.login {
  /* margin-top: 25%; */
  background-color: white;
  text-align: center;
}

.table-risk {
  width: 100%;
  border-spacing: 0;
}

.table-scroll {
  overflow: scroll;
  /* text-align: center; */
  /* max-width: 1280px; */
  margin-top: 20px;
  width: auto;
}

/* 
table,
th,
td {
  border: 1px solid;
}

table {
  width: 100%;
  max-width: 400px;
  height: 240px;
  margin: 0 auto;
  display: block;
  overflow-x: auto;
  border-spacing: 0;
}

tbody {
  white-space: nowrap;
}

th,
td {
  padding: 5px 10px;
  border-top-width: 0;
  border-left-width: 0;
}

th {
  position: sticky;
  top: 0;
  background: #fff;
  vertical-align: bottom;
}

th:last-child,
td:last-child {
  border-right-width: 0;
}

tr:last-child td {
  border-bottom-width: 0;
} */

.upload-image-card {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.box-result {
  width: 300px;
  height: 150px;
  padding: 15px;
  font-size: large;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ql-editor {
  overflow-y: scroll;
  min-height: 250px;
}

/* Table */
.ant-table-thead>tr>th,
.ant-table-thead>tr>td {
  color: white !important;
}

.ant-table-tbody>tr>td {
  word-wrap: break-word;
  word-break: break-all;
}

.ant-table-thead>tr:not(:last-child)>th,
.ant-table-thead>tr:not(:last-child)>td {
  background-color: #DC3238 !important;
  border-top: 1px solid #3d3935 !important;
}

.ant-table-thead>tr:last-child>th,
.ant-table-thead>tr:last-child>td {
  background-color: #DC3238 !important;
}

.ant-table-thead>tr:not(:last-child)>th {
  background-color: #DC3238 !important;
  border-bottom: 1px solid #3d3935 !important;
}

.ant-table-thead>tr>th,
.ant-table-thead>tr>td,
.ant-table-tbody>tr>th,
.ant-table-tbody>tr>td,
.ant-table-tfoot>tr>th,
.ant-table-tfoot>tr>td,
.ant-table-thead>tr:not(:last-child)>th {
  border-bottom: 1px solid #3d3935 !important;
  border-inline-end: 1px solid #3d3935 !important;
}

.ant-table-thead>tr:first-child>th:first-child,
.ant-table-thead>tr:first-child>td:first-child,
.ant-table-tbody>tr>th:first-child,
.ant-table-tbody>tr>td:first-child,
.ant-table-tfoot>tr:first-child>th:first-child,
.ant-table-tfoot>tr:first-child>td:first-child,
.ant-table-tfoot>tr:last-child>th:first-child,
.ant-table-tfoot>tr:last-child>td:first-child {
  border-inline-start: 1px solid #3d3935 !important;
}

.multi-thead .ant-table-thead>tr:not(:last-child)>th {
  border-inline-start: 1px solid #3d3935 !important;
}

/* desktops/laptops */
@media screen and (min-width: 960px) {
  .nomobile {
    display: block;
  }

  .showmobile {
    display: none;
  }
}

/* mobile devices */
@media screen and (max-width: 960px) {
  .nomobile {
    display: none;
  }

  .showmobile {
    display: block;
  }
}

.ck-editor__editable {
  min-height: 250px;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

